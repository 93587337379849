<template>
  <div class="onboarding-form">
    <div class="pb-5 pt-lg-0 pt-3">
      <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        Setup Mode <small>(Step 3 of 10)</small>
      </h3>
      <h5>
        Would you like us to prepopulate your settings with industry standard
        configurations or whould you like to setup yourself?
      </h5>
    </div>
    <div class="row m-0">
      <div
        @click="toggleAutomatic(true)"
        class="col bg-light-success px-6 py-5 rounded-xl mr-7 cursor-pointer"
      >
        <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
          <inline-svg src="media/svg/icons/code/thunder-circle.svg" />
          <i
            v-if="isAutomatic == true"
            class="fas fa-check-circle fa-2x text-success float-right"
          ></i>
        </span>
        <a href="#" class="text-success font-weight-bold font-size-h6 mt-2">
          Prepopulate My Settings
        </a>
        <p class="text-success">
          Setup my organisation for me I would adjust whatever i need to adjust
          as i go
        </p>
      </div>
      <div
        @click="toggleAutomatic(false)"
        class="col bg-light-warning px-6 py-5 rounded-xl cursor-pointer"
      >
        <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
          <inline-svg src="media/svg/icons/tools/swiss-knife.svg" />
          <i
            v-if="isAutomatic == false"
            class="fas fa-check-circle fa-2x text-danger float-right"
          ></i>
        </span>
        <a href="#" class="text-danger font-weight-bold font-size-h6 mt-2">
          I'll Setup Myself
        </a>
        <p class="text-danger">
          I know exactly what i am doing and i would like to setup my
          organisation myself
        </p>
      </div>
    </div>

    <div class="pb-lg-0 pb-5 float-right mt-5">
      <router-link to="/onboarding/company-setup" v-slot="{ navigate }">
        <button
          type="button"
          class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
          @click="navigate"
        >
          <i class="fas fa-arrow-left ml-2"></i>
          Back
        </button>
      </router-link>

      <button
        type="button"
        class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
        @click="selectSetup()"
      >
        <b-spinner
          v-if="loading == true"
          variant="light"
          label="Spinning"
        ></b-spinner>
        <span v-if="loading == false"
          >Next<i class="fas fa-arrow-right ml-2"></i
        ></span>
      </button>
    </div>
  </div>
</template>
<script>
import CompanyService from "@/core/services/company.service.js";
import { mapGetters, mapState } from "vuex";
import { UPDATE_AUTH } from "@/core/services/store/auth.module";
export default {
  data() {
    return {
      isAutomatic: true,
      loading: false
    };
  },
  components: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"])
  },
  mounted() {},
  methods: {
    selectSetup() {
      this.loading = true;
      var data = {
        previous_onboarding_stage: 3,
        onboarding_stage:
          this.currentUser.company.is_multiple_locations == true ? 4 : 5,
        onboarding_complete: false
      };

      CompanyService.setOnboardingStage(data)
        .then(response => {
          try {
            this.$store.dispatch(UPDATE_AUTH, response.data);
            if (this.isAutomatic == true) {
              CompanyService.autoPopulate({
                company_id: this.currentUser.company_id
              })
                .then(() => {
                  try {
                    if (
                      this.currentUser.company.is_multiple_locations == true
                    ) {
                      this.$router.push({
                        name: "onboarding-company-locations"
                      });
                    } else {
                      this.$router.push({
                        name: "onboarding-energy-streams"
                      });
                    }
                  } catch (error) {
                    console.log(error);
                    this.makeToast(
                      "danger",
                      "Oops",
                      "An unexpected exception has occured. Please give it a minute and refresh page"
                    );
                    this.loading = false;
                  }
                })
                .catch(error => {
                  if (error.data) {
                    this.makeToast(
                      "danger",
                      error.statusText,
                      error.data.message
                    );
                  } else {
                    this.makeToast(
                      "danger",
                      "Oops",
                      "An unexpected exception has occured. Please give it a minute and refresh page"
                    );
                  }
                  this.loading = false;
                });
            } else {
              if (this.currentUser.company.is_multiple_locations == true) {
                this.$router.push({
                  name: "onboarding-company-locations"
                });
              } else {
                this.$router.push({
                  name: "onboarding-energy-streams"
                });
              }
            }
          } catch (error) {
            console.log(error);
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
            this.loading = false;
          }
        })
        .catch(error => {
          if (error.data) {
            this.makeToast("danger", error.statusText, error.data.message);
          } else {
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
          }
          this.loading = false;
        });
    },

    toggleAutomatic(value) {
      this.isAutomatic = value;
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
